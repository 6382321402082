import api from '@/apis'

export default {
  state: {
    live_loading: false,
    live_urls: []
  },
  mutations: {
    SET_REPAIR_LIVE_LOADING(state, payload) {
      state.loading = payload
    },
    SET_REPAIR_LIVE_URLS(state, payload) {
      state.live_urls = payload
    }
  },
  actions: {
    async fetchRepairLiveUrl({ commit }) {
      try {
        commit('SET_REPAIR_LIVE_LOADING', true)

        const response = await api.getRepairLiveUrls()
        if (response.data.success) {
          commit('SET_REPAIR_LIVE_URLS', response.data.data.urls)
          if (response.data.data.speech || response.data.data.speech_url) commit('INIT_AVATAR_SPEECH', { speech: response.data.data.speech, speech_url: response.data.data.speech_url })
        } else throw Error(response.data.message)
      } catch (error) {
        commit('SET_SNACKBAR', { status: true, color: 'error', text: error.message, icon: 'close', timeout: '2000' })
      } finally {
        commit('SET_REPAIR_LIVE_LOADING', false)
      }
    }
  }
}

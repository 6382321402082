import axios from './axios.js'
import url from './urls.js'

export default {
  // Login
  login: (data) => axios.post(url.login, data.params),
  getDashboardPreLoginSpeech: () => axios.get(url.dashboard_pre_login),
  getDashboardSpeech: () => axios.get(url.dashboard),

  // Repair
  getRepairLiveUrls: () => axios.get(url.repair_live),

  // Car
  getCarSpeech: () => axios.get(url.car),
  getCarAiUrl: () => axios.get(url.car_ai),
  getCarLiveUrls: () => axios.get(url.car_live_v2),

  // Shop
  getShopSpeech: () => axios.get(url.shop),
  getShopAiUrl: () => axios.get(url.shop_ai),
  getShopLiveUrls: () => axios.get(url.shop_live),
  getShopMallUrl: () => axios.get(url.shop_mall),

  // Game
  getGameAiUrl: () => axios.get(url.game_ai),
  getGameRank: () => axios.get(url.game_leaderboard)
}

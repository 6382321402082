<template>
  <v-card v-if="visibility && speech" class="avatar-speech" color="transparent" flat>
    {{ speech }}
    <v-img @click="play" class="play-btn" :src="require('@/assets/images/playBtn.png')" />
  </v-card>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'AvatarSpeech',
  computed: {
    ...mapState(['avatarSpeech']),
    visibility() {
      return this.avatarSpeech.visibility
    },
    speech() {
      return this.avatarSpeech.speech
    },
    speech_url() {
      return this.avatarSpeech.speech_url
    }
  },
  methods: {
    play() {
      this.$store.commit('PLAY_AVATAR_SPEECH_AUDIO')
    }
  }
}
</script>

<style scoped>
.avatar-speech {
  z-index: 1;
  position: absolute;
  max-width: 16rem;
  color: white;
  padding: 0.75rem;
  border-radius: 0.75rem 0.75rem 0.75rem 0px;
  border: 0.0625rem solid rgba(255, 255, 255, 0.8) !important;

  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.5rem;
}

.play-btn {
  margin-top: 0.5rem;
  max-width: 1.25rem;
  max-height: 1.25rem;
  border-radius: 0 !important;
}

@media screen and (orientation: landscape) {
  .avatar-speech {
    left: 11rem;
    top: 8rem;
  }
}

@media screen and (orientation: portrait) {
  .avatar-speech {
    left: 9rem;
    bottom: 11rem;
  }

  @media (max-height: 900px) {
    .avatar-speech {
      bottom: 7rem;
    }
  }
  @media (max-height: 700px) {
    .avatar-speech {
      bottom: 5rem;
    }
  }
  @media (min-width: 393px) and (max-width: 430px) {
    .avatar-speech {
      width: 16.286rem;
      font-size: 1.15rem;
      left: 10.5rem;
      padding: 1rem;
    }
    @media (min-height: 852px) and (max-height: 932px) {
      .avatar-speech {
        bottom: 3rem;
      }
    }
  }
}
</style>

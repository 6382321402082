import en from 'vuetify/es5/locale/en'

// Usage -> {{$vuetify.lang.t('$vuetify.close')}} -> 關閉

// Usage in vuex store ->
// import Vuetify from '@/plugins/vuetify'
// Vuetify.framework.lang.t('$vuetify.close')

// For variable -> {{$vuetify.lang.t('$vuetify.__XXX.abc', 'world')}}
// In lang file, __XXX:{abc: 'Hello {0}'} -> result: 'Hello world'
// If no variable needed for certain case, still require empty string -> {{$vuetify.lang.t('$vuetify.__XXX.abc', '')}}

// Multiple variables -> {{$vuetify.lang.t('$vuetify.__XXX.abc', 'Hello', 'world', '!')}}
// In lang file, __XXX:{abc: '{0} {1}{2}'} -> result: 'Hello world!'

export default {
  ...en
}

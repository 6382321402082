import api from '@/apis'

export default {
  state: {
    ai: {
      loading: false,
      url: null
    },
    live: {
      loading: false,
      urls: []
    }
  },
  mutations: {
    SET_CAR_AI_LOADING(state, payload) {
      state.ai.loading = payload
    },
    SET_CAR_AI_URL(state, payload) {
      state.ai.url = payload
    },
    SET_CAR_LIVE_LOADING(state, payload) {
      state.live.loading = payload
    },
    SET_CAR_LIVE_URLS(state, payload) {
      state.live.urls = payload
    }
  },
  actions: {
    async fetchCarSpeech({ commit }) {
      try {
        const response = await api.getCarSpeech()
        if (response.data.success) {
          if (response.data.data.speech || response.data.data.speech_url) commit('INIT_AVATAR_SPEECH', { speech: response.data.data.speech, speech_url: response.data.data.speech_url })
        } else throw Error(response.data.message)
      } catch (error) {
        commit('SET_SNACKBAR', { status: true, color: 'error', text: error.message, icon: 'close', timeout: '2000' })
      }
    },
    async fetchCarAiUrl({ commit }) {
      try {
        commit('SET_CAR_AI_LOADING', true)

        const response = await api.getCarAiUrl()
        if (response.data.success) {
          commit('SET_CAR_AI_URL', response.data.data.url)
          if (response.data.data.speech || response.data.data.speech_url) commit('INIT_AVATAR_SPEECH', { speech: response.data.data.speech, speech_url: response.data.data.speech_url })
        } else throw Error(response.data.message)
      } catch (error) {
        commit('SET_SNACKBAR', { status: true, color: 'error', text: error.message, icon: 'close', timeout: '2000' })
      } finally {
        commit('SET_CAR_AI_LOADING', false)
      }
    },
    async fetchCarLiveUrls({ commit }) {
      try {
        commit('SET_CAR_LIVE_LOADING', true)

        const response = await api.getCarLiveUrls()
        if (response.data.success) {
          commit('SET_CAR_LIVE_URLS', response.data.data.urls)
          if (response.data.data.speech || response.data.data.speech_url) commit('INIT_AVATAR_SPEECH', { speech: response.data.data.speech, speech_url: response.data.data.speech_url })
        } else throw Error(response.data.message)
      } catch (error) {
        commit('SET_SNACKBAR', { status: true, color: 'error', text: error.message, icon: 'close', timeout: '2000' })
      } finally {
        commit('SET_CAR_LIVE_LOADING', false)
      }
    }
  }
}

import api from '@/apis'

export default {
  state: {
    ai: {
      loading: false,
      url: null
    },
    rank: {
      loading: false,
      details: {}
    }
  },
  mutations: {
    SET_GAME_AI_LOADING(state, payload) {
      state.ai.loading = payload
    },
    SET_GAME_AI_URL(state, payload) {
      state.ai.url = payload
    },
    SET_GAME_RANK_LOADING(state, payload) {
      state.rank.loading = payload
    },
    SET_GAME_RANK_DETAILS(state, payload) {
      state.rank.details = payload
    }
  },
  actions: {
    async fetchGameAiUrl({ commit }) {
      try {
        commit('SET_GAME_AI_LOADING', true)

        const response = await api.getGameAiUrl()
        if (response.data.success) {
          commit('SET_GAME_AI_URL', response.data.data.url)
          if (response.data.data.speech || response.data.data.speech_url) commit('INIT_AVATAR_SPEECH', { speech: response.data.data.speech, speech_url: response.data.data.speech_url })
        } else throw Error(response.data.message)
      } catch (error) {
        commit('SET_SNACKBAR', { status: true, color: 'error', text: error.message, icon: 'close', timeout: '2000' })
      } finally {
        commit('SET_GAME_AI_LOADING', false)
      }
    },
    async fetchGameRank({ commit }) {
      try {
        commit('SET_GAME_RANK_LOADING', true)

        const response = await api.getGameRank()
        if (response.data.success) {
          commit('SET_GAME_RANK_DETAILS', response.data.data)
          if (response.data.data.speech || response.data.data.speech_url) commit('INIT_AVATAR_SPEECH', { speech: response.data.data.speech, speech_url: response.data.data.speech_url })
        } else throw Error(response.data.message)
      } catch (error) {
        commit('SET_SNACKBAR', { status: true, color: 'error', text: error.message, icon: 'close', timeout: '2000' })
      } finally {
        commit('SET_GAME_RANK_LOADING', false)
      }
    }
  }
}

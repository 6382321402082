<template>
  <div>
    <v-menu ref="menu_btn" offset-y bottom :close-on-content-click="true" max-width="100%" transition="slide-y-transition" style="borer-radius: 0" content-class="menu-btn-container">
      <template v-slot:activator="{ on, attrs }">
        <v-btn class="menu-btn-text" outlined dark v-bind="attrs" v-on="on">
          <v-icon class="menu-btn-icon">mdi-menu</v-icon>
          <p>選單</p>
        </v-btn>
      </template>
      <div class="menu-btn-card">
        <v-row no-gutters @click="$router.push(item.route_path)" v-for="(item, i) in filtered_menu_items" :key="i" class="menu-btn-item"> {{ item.title }} </v-row>
      </div>
    </v-menu>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'MenuButton',
  data() {
    return {
      menu_items: [
        { title: '維修間直播', route_path: '/live' },
        { title: '新車賞車服務', route_path: '/car' },
        { title: '虛擬AI商城', route_path: '/shop' }
        // { title: '異地共遊遊戲', route_path: '/game' }
      ]
    }
  },
  computed: {
    ...mapState(['global']),
    page_access() {
      return this.global.page_access
    },
    filtered_menu_items() {
      if (this.page_access === 'all') return this.menu_items
      let filtered_items = this.menu_items.filter((menu_item) => this.page_access.includes(menu_item.route_path))
      filtered_items.push({ title: '返回主頁', route_path: '/home' })
      return filtered_items
    }
  }
}
</script>

<style scope>
.menu-btn-icon {
  width: 1.125rem !important;
  height: 1.125rem !important;
  margin-right: 0.375rem;
}

.menu-btn-text {
  color: #fff;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.5rem;
  letter-spacing: 0.03125rem;
}
.menu-btn-text p {
  margin-bottom: 0;
}
.menu-btn-container {
  top: 4.75rem !important;
}

.menu-btn-card {
  width: 100vw;
  padding: 0 2rem 2rem 2rem;
  background-color: #1a1a1a !important;
}

.menu-btn-item {
  padding: 1.5rem 0;
  color: #fff;
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 116%;
  letter-spacing: 0.05rem;
  border-bottom: 1px solid #fff;
}
@media screen and (orientation: portrait) {
  @media (min-width: 393px) and (max-width: 430px) {
    .menu-btn-text p {
      display: none;
    }
    .menu-btn-text .v-icon.v-icon {
      margin-right: 0;
    }
  }
  .menu-btn-text {
    height: 2.55rem !important;
    padding: 0.5rem 1.45rem !important;
    width: 4.1rem;
  }
}
</style>

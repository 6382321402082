import api from '@/apis'

export default {
  state: {
    loading: false
  },
  mutations: {
    SET_HOME_LOADING(state, payload) {
      state.loading = payload
    }
  },
  actions: {
    async fetchDashboardSpeech({ commit }) {
      try {
        commit('SET_HOME_LOADING', true)

        const response = await api.getDashboardSpeech()
        if (response.data.success) {
          if (response.data.data.speech || response.data.data.speech_url) commit('INIT_AVATAR_SPEECH', { speech: response.data.data.speech, speech_url: response.data.data.speech_url })
        } else throw Error(response.data.message)
      } catch (error) {
        commit('SET_SNACKBAR', { status: true, color: 'error', text: error.message, icon: 'close', timeout: '2000' })
      } finally {
        commit('SET_HOME_LOADING', false)
      }
    }
  }
}

import axios from 'axios'
import store from '@/store'

const instance = axios.create({
  baseURL: process.env.VUE_APP_BACKEND_SERVER_ENDPOINT
})

// Request interceptor
instance.interceptors.request.use(
  (config) => {
    if (!config.url.includes('/login')) {
      config.headers.access_token = store.state.global.access_token
    }
    return config
  },
  (error) => {
    console.error('error:', error)
    Promise.reject(error)
  }
)

// Response interceptor
instance.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    console.error('error:' + error)
    return Promise.reject(error)
  }
)

export default instance

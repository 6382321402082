export default {
  state: {
    visibility: false,
    license_plate: null
  },
  mutations: {
    INIT_LOGIN_DIALOG(state) {
      state.license_plate = null
      state.visibility = true
    },
    RESET_LOGIN_DIALOG(state) {
      state.license_plate = null
      state.visibility = false
    },
    SET_LOGIN_DIALOG_LICENSE_PLATE(state, payload) {
      state.license_plate = payload
    }
  },
  actions: {}
}

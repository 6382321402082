<template>
  <v-app class="bg">
    <v-main>
      <Navbar />
      <Avatar v-if="notShopMall" />
      <AvatarSpeech />
      <v-fade-transition mode="out-in">
        <router-view />
      </v-fade-transition>
    </v-main>
  </v-app>
</template>

<script>
import Navbar from '@/components/Navbar'
import Avatar from '@/components/Avatar'
import AvatarSpeech from '@/components/Widgets/AvatarSpeech'

export default {
  name: 'App',
  components: { Navbar, Avatar, AvatarSpeech },
  computed: {
    notShopMall() {
      return this.$route.path !== '/shop/mall'
    }
  },
  created() {
    this.$store.commit('SET_AVATAR_SPEECH_AUDIO', new Audio())
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@300;400;500;600;700&display=swap');
* {
  font-family: 'Noto Sans TC';
  overflow-y: hidden;
}

:root {
  font-size: 16px;
}

html {
  background-color: black;
}

.box-area {
  /* Background Image Setting */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 0 !important;
}

.box-area-fullscreen {
  height: 100%;
}

.box-area-with-nav {
  height: calc(100% - 4.75rem);
}

.v-card {
  border-radius: 0;
}

.outline-btn {
  padding: 0.375rem 1.25rem !important;
}

.v-btn:not(.v-btn--round).v-size--default {
  min-width: 0 !important;
  height: 2.25rem;
  padding: 0.375rem 1.25rem;
}

.v-icon.v-icon {
  font-size: unset;
}

.sub-page-btn-container {
  flex-direction: column;
  gap: 1rem;
}

@media screen and (orientation: landscape) {
  .sub-page-btn-container {
    position: absolute;
    top: 30%;
    left: 12rem;
  }
  @media (min-height: 1400px) {
    :root {
      font-size: 24px;
    }
  }
  @media (min-height: 1300px) {
    :root {
      font-size: 22px;
    }
  }
  @media (min-height: 1200px) {
    :root {
      font-size: 20px;
    }
  }
  @media (min-height: 1100px) {
    :root {
      font-size: 18px;
    }
  }
  @media (max-height: 800px) {
    :root {
      font-size: 14px;
    }
  }
  @media (max-height: 700px) {
    :root {
      font-size: 12px;
    }
  }
  @media (max-height: 600px) {
    :root {
      font-size: 10px;
    }
  }
  @media (max-height: 400px) {
    :root {
      font-size: 8px;
    }
  }
}

@media screen and (orientation: portrait) {
  .sub-page-menu {
    justify-content: center;
    align-items: center;
  }

  @media (min-width: 600px) {
    :root {
      font-size: 18px;
    }
  }
  @media (min-width: 900px) {
    :root {
      font-size: 24px;
    }
  }
  @media (min-width: 1200px) {
    :root {
      font-size: 30px;
    }
  }
  @media (min-width: 1500px) {
    :root {
      font-size: 36px;
    }
  }
  @media (min-width: 1800px) {
    :root {
      font-size: 42px;
    }
  }
  @media (max-width: 500px) {
    :root {
      font-size: 14px;
    }
  }
  @media (max-width: 400px) {
    :root {
      font-size: 12px;
    }
  }
  @media (max-width: 300px) {
    :root {
      font-size: 10px;
    }
  }
  @media (max-width: 200px) {
    :root {
      font-size: 8px;
    }
  }
}
</style>

<style scoped>
.bg {
  background-color: black;
}
</style>

<template>
  <!-- <v-img class="avatar" :src="require('@/assets/images/avatar.png')" /> -->
  <v-img class="avatar" cover :src="require('@/assets/images/idle_carey.gif')" @error="onImageError()"> </v-img>
</template>

<script>
export default {
  name: 'Avatar',
  methods: {
    onImageError() {
      event.target.src = require('@/assets/images/avatar.png')
    }
  }
}
</script>

<style scoped>
.avatar {
  z-index: 1;
  position: absolute;
  height: 50rem;
  width: 20rem;
  bottom: 0;
  left: -3rem;
}

@media screen and (orientation: landscape) {
  @media (max-width: 1180px) {
    .avatar {
      bottom: -5rem;
    }
  }

  @media (max-height: 900px) {
    .avatar {
      bottom: -5rem;
    }
  }
  @media (max-height: 840px) {
    .avatar {
      bottom: -10rem;
    }
  }
  @media (max-height: 500px) {
    .avatar {
      bottom: -20rem;
    }
  }
}

@media screen and (orientation: portrait) {
  .avatar {
    height: 30rem;
    width: 6rem;
    bottom: -12rem;
    left: 1rem;
  }

  @media (max-height: 900px) {
    .avatar {
      bottom: -14rem;
    }
  }
  @media (max-height: 700px) {
    .avatar {
      bottom: -16rem;
    }
  }
  @media (min-width: 393px) and (max-width: 430px) {
    .avatar {
      left: 2.5rem;
    }
    @media (min-height: 852px) and (max-height: 932px) {
      .avatar {
        bottom: -20rem;
      }
    }
  }
}
</style>

export default {
  // Login
  login: '/login',
  dashboard_pre_login: '/dashboard/prelogin',
  dashboard: '/dashboard',

  // Repair
  repair_live: '/repair/live',

  // Car
  car: '/car',
  car_ai: '/car/ai',
  car_live: '/car/live',
  car_live_v2: '/car/live/v2',

  // Shop
  shop: '/shop',
  shop_ai: '/shop/ai',
  shop_live: '/shop/live',
  shop_mall: '/shop/mall',

  // Game
  game_ai: '/game/ai',
  game_leaderboard: '/game/leaderboard'
}

import api from '@/apis'
import router from '@/router'

export default {
  state: {
    status: null,
    access_token: null,
    page_access: []
  },
  mutations: {
    SET_AUTH_STATUS(state, payload) {
      state.status = true
      state.access_token = payload.access_token
      state.page_access = payload.page_access && payload.page_access.length ? payload.page_access : 'all'
    }
  },
  actions: {
    async loginGuest({ commit }, payload) {
      try {
        commit('SET_SNACKBAR', { status: true, color: 'loading', text: '', loading: true })
        const response = await api.login(payload)
        if (response.data.success) {
          commit('SET_SNACKBAR', { status: true, color: 'success', text: response.data.message, icon: 'check', timeout: '2000' })
          commit('SET_AUTH_STATUS', response.data.data)
          router.push({ path: '/home' })
        } else throw Error(response.data.message)
      } catch (error) {
        commit('SET_SNACKBAR', { status: true, color: 'error', text: error.message, icon: 'close', timeout: '2000' })
      }
    }
  }
}

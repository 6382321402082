<template>
  <v-expand-transition>
    <div v-if="visibility">
      <v-row no-gutters class="navbar" align="center">
        <!-- <v-img class="navbar-logo" :src="require('@/assets/images/logo.svg')" contain /> -->
        <span class="navbar-title">瑞達線上服務平台</span>
        <v-spacer></v-spacer>
        <MenuButton v-if="menu_btn_visibility" />
      </v-row>
    </div>
  </v-expand-transition>
</template>

<script>
import MenuButton from '@/components/Widgets/MenuButton'

export default {
  name: 'Navbar',
  components: { MenuButton },
  data() {
    return {
      visibility: false,
      menu_btn_visibility: false,
      while_list: ['/', '/home', '/car', '/shop'], // Navbar will only display in these white listed nav
      menu_while_list: ['/home', '/car', '/shop'] // Menu btn will only display in these white listed nav
    }
  },
  watch: {
    '$route.fullPath': {
      immediate: true,
      handler(curr_path) {
        this.visibility = this.while_list.includes(curr_path)
        this.menu_btn_visibility = this.menu_while_list.includes(curr_path)
      }
    }
  }
}
</script>

<style scoped>
.navbar {
  background-color: #1a1a1a;
  padding: 1rem 2rem;
  height: 4.75rem;
}

.navbar-logo {
  max-width: 13rem;
}

.navbar-title {
  color: #fff;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
  letter-spacing: 0.15625rem;
}

@media screen and (orientation: landscape) {
  @media (max-width: 1180px) {
  }
}

@media screen and (orientation: portrait) {
  @media (min-width: 393px) and (max-width: 430px) {
    .navbar-title {
      font-size: 1.2rem;
      line-height: 1.7rem;
    }
  }
}
</style>

export default {
  state: {
    visibility: false,
    audio: null,
    speech: null,
    speech_url: null
  },
  mutations: {
    INIT_AVATAR_SPEECH(state, payload) {
      state.visibility = false
      if (payload.speech) {
        state.speech = payload.speech
      } else state.speech = ''

      if (payload.speech_url) {
        state.speech_url = payload.speech_url
        state.audio.src = payload.speech_url
        if (payload.page !== 'login') state.audio.play()
      }

      state.visibility = true
    },
    RESET_AVATAR_SPEECH(state) {
      state.visibility = false
      if (state.audio) state.audio.pause()
    },
    SET_AVATAR_SPEECH_AUDIO(state, payload) {
      state.audio = payload
    },
    PLAY_AVATAR_SPEECH_AUDIO(state) {
      if (state.audio && state.audio.src && state.page !== 'login') {
        if (state.audio.paused) state.audio.play()
      }
    }
  }
}

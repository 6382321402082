// Libraries
import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import modules from './module_loader'
Vue.use(Vuex)

export default new Vuex.Store({
  modules
})

import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

Vue.use(VueRouter)

const routes = [
  {
    path: '*',
    redirect: '/'
  },
  {
    path: '/',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login/index.vue')
  },
  {
    path: '/home',
    name: 'Home',
    component: () => import(/* webpackChunkName: "home" */ '../views/Home/index.vue')
  },
  {
    path: '/live',
    name: 'Repair Live',
    component: () => import(/* webpackChunkName: "repairLive" */ '../views/RepairLive/index.vue')
  },
  {
    path: '/car',
    name: 'Car',
    component: () => import(/* webpackChunkName: "car" */ '../views/Car/index.vue')
  },
  {
    path: '/car/ai',
    name: 'Car AI',
    component: () => import(/* webpackChunkName: "carAI" */ '../views/Car/AI/index.vue')
  },
  {
    path: '/car/live',
    name: 'Car Live',
    component: () => import(/* webpackChunkName: "carLive" */ '../views/Car/Live/index.vue')
  },
  {
    path: '/shop',
    name: 'Shop',
    component: () => import(/* webpackChunkName: "shop" */ '../views/Shop/index.vue')
  },
  {
    path: '/shop/ai',
    name: 'Shop AI',
    component: () => import(/* webpackChunkName: "shopAI" */ '../views/Shop/AI/index.vue')
  },
  {
    path: '/shop/live',
    name: 'Shop Live',
    component: () => import(/* webpackChunkName: "shopLive" */ '../views/Shop/Live/index.vue')
  },
  {
    path: '/shop/mall',
    name: 'Shop Mall',
    component: () => import(/* webpackChunkName: "shopAI" */ '../views/Shop/Mall/index.vue')
  }
  // {
  //   path: '/game',
  //   name: 'Game',
  //   component: () => import(/* webpackChunkName: "game" */ '../views/Game/index.vue')
  // },
  // {
  //   path: '/game/ai',
  //   name: 'Game AI',
  //   component: () => import(/* webpackChunkName: "gameAI" */ '../views/Game/AI/index.vue')
  // },
  // {
  //   path: '/game/rank',
  //   name: 'Game Rank',
  //   component: () => import(/* webpackChunkName: "gameRank" */ '../views/Game/Rank/index.vue')
  // }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  store.commit('RESET_AVATAR_SPEECH')
  const status = store.state.global.status
  if (!status && to.path !== '/') next('/')

  next()
})

export default router
